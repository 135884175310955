<template>
  <fragment>
    <CardTable
      :title="title"
      :subTitle="subTitle"
      :addFilter="addFilter"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onFilterClick="onFilterClick"
      @onRowSelected="onRowSelected"
      @onPerPageChange="onPerPageChange"
      @onPageChange="onPageChange"
      @onSearch="onSearch"
    >
      <template #search>
        <div class="ml-3">
          <DatePicker
            :initialDates="[
              serverParams.dateRange.startDate,
              serverParams.dateRange.endDate,
            ]"
            :dateInput="dateInput"
            :switchButtonInitial="true"
            :showHelperButtons="true"
            language="id"
            @date-applied="changeDate"
          />
        </div>
      </template>
      <template #filter>
        <b-row class="p-3">
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Store"
              v-model="serverParams.storeId"
              :clearable="false"
              :options="options.store"
              @input="getStoreById"
            />
          </b-col>
          <b-col xl="3">
            <b-input
              class="mb-2"
              placeholder="Nomor Transaksi"
              v-model="serverParams.transactionNumber"
              @input="onFilter"
            />
          </b-col>
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Metode Pembayaran"
              v-model="serverParams.paymentMethodId"
              :options="options.paymentMethod"
              @input="onFilter"
            />
          </b-col>
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Kasir"
              v-model="serverParams.cashierId"
              :options="options.cashier"
              @input="onFilter"
            />
          </b-col>
        </b-row>
      </template>
      <template #empty>
        <EmptyTable title="Belum ada data penjualan" :description="subTitle" />
      </template>
      <template #cell(created.createdAt)="data">
        {{ dateTimeFormat(data.value) }}
      </template>
      <template #cell(customer.label)="data">{{ data.value || "-" }}</template>
      <template #cell(carts)="data">
        {{ truncate(setProductName(data.value), 50) }}
      </template>
      <template #cell(subTotal)="data">{{ numberFormat(data.value) }}</template>
      <template #cell(tax)="data">{{ numberFormat(data.value) }}</template>
      <template #cell(discount)="data">{{ numberFormat(data.value) }}</template>
      <template #cell(grandTotal)="data">
        {{ numberFormat(data.value) }}
      </template>
      <template #cell(amount)="data">
        {{ numberFormat(data.value) }}
      </template>
      <template #cell(paymentRemaining)="data">
        {{ numberFormat(data.value) }}
      </template>
    </CardTable>

    <b-modal
      v-model="modal.show"
      title="Transaksi"
      size="xl"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Tutup"
    >
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="6">
          <TransactionDetail :form="modal.form" />
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="6">
          <CardForm title="Bagikan ulang tagihan">
            <template #form>
              <div class="card-body">
                <b-button
                  block
                  size="lg"
                  variant="outline-secondary"
                  @click="handlePrint"
                >
                  <div class="d-flex flex-column">
                    <div class="m-2">
                      <i class="flaticon2-printer" />
                    </div>
                    Cetak Tagihan
                  </div>
                </b-button>

                <div class="d-flex justify-content-between mt-4">
                  <b-button
                    block
                    size="lg"
                    :variant="
                      modal.method == `email` ? `success` : `outline-secondary`
                    "
                    class="m-0 mr-2"
                    @click="handleChooseShareMethod('email')"
                  >
                    <div class="d-flex flex-column">
                      <div class="m-2">
                        <i class="flaticon-multimedia" />
                      </div>
                      Bagikan via Email
                    </div>
                  </b-button>

                  <b-button
                    block
                    size="lg"
                    :variant="
                      modal.method == `whatsapp`
                        ? `success`
                        : `outline-secondary`
                    "
                    class="m-0 ml-2"
                    @click="handleChooseShareMethod('whatsapp')"
                  >
                    <div class="d-flex flex-column">
                      <div class="m-2">
                        <i class="flaticon-whatsapp" />
                      </div>
                      Bagikan via Whatsapp
                    </div>
                  </b-button>
                </div>

                <b-input-group
                  v-if="[`email`, `whatsapp`].includes(modal.method)"
                  class="mt-2 mb-8"
                >
                  <template #append>
                    <b-button @click="handleShare" variant="success">
                      Bagikan
                    </b-button>
                  </template>

                  <b-form-input
                    v-if="modal.method == `email`"
                    v-model="modal.shareTo"
                    type="email"
                    autocomplete="off"
                    placeholder="Masukkan email pelanggan disini..."
                  />
                  <b-form-input
                    v-if="modal.method == `whatsapp`"
                    v-model="modal.shareTo"
                    type="tel"
                    autocomplete="off"
                    placeholder="Masukkan nomor whatsapp pelanggan disini..."
                  />
                </b-input-group>
              </div>
            </template>
          </CardForm>
        </b-col>
      </b-row>
    </b-modal>
  </fragment>
</template>

<script>
import { apiUrl } from "@/core/services/api.url";
import { mapGetters } from "vuex";
import { laporanTransaksi as columns } from "@/core/datasource/column";
import {
  pageSize,
  getStore,
  getPaymentMethod,
  getCashier,
  setRupiah,
  numberFormat,
  normalizer,
  getDate,
  dateTimeFormat,
  toDate,
  truncate,
  isNullOrEmpty,
} from "@/core/utils";
import { SET_STORE } from "@/core/services/store/personalize.module.js";
import TransactionDetail from "@/view/pages/transaction/Detail.vue";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";

export default {
  components: {
    TransactionDetail,
    DatePicker,
  },
  data: () => ({
    businessId:
      process.env.NODE_ENV === "production"
        ? apiUrl.business.prodId
        : apiUrl.business.devId,
    basePrintURL:
      process.env.NODE_ENV === "production"
        ? apiUrl.print.prod
        : apiUrl.print.dev,
    title: "Transaksi",
    subTitle: "Menampilkan data transaksi per periode",
    searchText: "Cari nomor transaksi",
    addFilter: false,
    serverParams: {
      pageNumber: 1,
      pageSize,
      dateRange: {
        startDate: toDate(getDate("YYYY/MM/DD 00:00:00")),
        endDate: toDate(getDate("YYYY/MM/DD 23:59:59")),
      },
      transactionNumber: null,
      storeId: null,
      cashierId: null,
      orderBy: "",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    options: {
      store: [],
      paymentMethod: [],
      cashier: [],
    },
    modal: {
      show: false,
      form: {},
      method: null,
      shareTo: null,
      customer: null,
    },
    dateInput: {
      inputClass: "form-control",
    },
  }),
  created() {
    const self = this;

    getStore().then((response) => {
      self.options.store = response.data.map((x) => ({
        id: x.id,
        label: x.storeName,
      }));
      if (self.options.store.length > 0) {
        if (self.store != null) {
          self.serverParams.storeId = self.store.id;
        } else {
          self.serverParams.storeId = self.options.store[0].id;
        }
      }
    });
  },
  computed: {
    ...mapGetters(["store"]),
  },
  methods: {
    numberFormat,
    setRupiah,
    normalizer,
    dateTimeFormat,
    truncate,
    changeDate(from, to) {
      const self = this;
      self.serverParams.dateRange.startDate = from;
      self.serverParams.dateRange.endDate = to;
      self.getAll();
    },
    setProductName(products) {
      let labels = [];

      products.forEach((product) => {
        let result = "";
        let variant = product.productVariants.join(", ");
        if (product.productVariants.length > 0) {
          result = `${product.productName} ${variant}`;
        } else {
          result = product.productName;
        }

        if (product.quantity > 1) result += ` x${product.quantity}`;

        labels.push(result);
      });

      return labels.join(", ");
    },
    getStoreById(storeId) {
      const self = this;

      if (storeId != null) {
        self.$store
          .dispatch("apis/get", {
            url: `store/${storeId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.$store.dispatch(SET_STORE, response.data);
            }
          })
          .finally(() => {
            getPaymentMethod(storeId).then((response) => {
              self.options.paymentMethod = response.data.map((x) => ({
                id: x.id,
                label: x.label,
              }));
            });
            getCashier(storeId).then((response) => {
              self.options.cashier = response.data.map((x) => ({
                id: x.id,
                label: x.fullName,
              }));
            });
            self.getAll();
          });
      }
    },
    getTransactionById(transactionId) {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/${transactionId}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.modal.form = {
              transactionId: transactionId,
              transactionNumber: response.data.transactionNumber,
              transactionDate: response.data.created?.createdAt,
              customer: response.data.customer,
              subTotal: response.data.subTotal,
              taxPercentage: response.data.taxPercentage,
              tax: response.data.tax,
              discount: response.data.discount,
              grandTotal: response.data.grandTotal,
              amount: response.data.amount,
              paymentAmount: response.data.paymentAmount,
              paymentChange: response.data.paymentChange,
              paymentRemaining: response.data.paymentRemaining,
              outstanding: response.data.outstanding,
              paymentMethod: response.data.paymentMethod,
              carts: response.data.carts,
              created: response.data.created,
            };
          }
        })
        .finally(() => {
          self.modal.show = true;
          self.getCustomerById(self.modal.form?.customer?.id);
          loader.hide();
        });
    },
    getCustomerById(customerId) {
      const self = this;

      if (!isNullOrEmpty(customerId) && self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/member/${customerId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.modal.customer = {
                id: response.data.id,
                fullName: response.data.fullName,
                membershipNumber: response.data.membershipNumber,
                email: response.data.email,
                phone: response.data.phone,
              };
            }
          });
      }
    },
    getAll() {
      const self = this;

      let _serverParams = {
        pageNumber: self.serverParams.pageNumber,
        pageSize: self.serverParams.pageSize,
        transactionNumber: self.serverParams.transactionNumber,
        paymentMethodId: self.serverParams.paymentMethodId,
        cashierId: self.serverParams.cashierId,
        startDate: self.serverParams.dateRange.startDate,
        endDate: self.serverParams.dateRange.endDate,
      };
      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/report/${self.serverParams.storeId}/transaction`,
          params: _serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onFilterClick(params) {
      this.addFilter = params;
    },
    onRowSelected(items) {
      const self = this;
      self.getTransactionById(items[0].id);
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onSearch(params) {
      const self = this;
      self.updateParams({
        productName: params,
      });
      self.getAll();
    },
    onFilter() {
      const self = this;
      self.getAll();
    },
    handleChooseShareMethod(method) {
      const self = this;

      self.modal.method = method;
      if (method == "email") self.modal.shareTo = self.modal.customer?.email;
      else if (method == "whatsapp")
        self.modal.shareTo = self.modal.customer?.phone;
    },
    handleShare() {
      const self = this;

      if (isNullOrEmpty(self.modal.shareTo)) {
        self.$message.warning({
          zIndex: 10000,
          message: `Maaf, ${self.modal.method} diisi dulu ya`,
        });

        return;
      }

      let payload = {
        transactionId: self.modal.form.transactionId,
        method: self.modal.method,
        shareTo: self.modal.shareTo,
      };

      self.$dialog
        .confirm(
          `Kamu yakin mau membagikan pesanan ini melalui ${self.modal.method} ?`,
          {
            okText: "Ya, Bagikan",
            cancelText: "Ga jadi",
            loader: true,
          }
        )
        .then((dialog) => {
          self.$store
            .dispatch("apis/post", {
              url: `/transaction/${self.store.id}/share`,
              params: payload,
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.modal.proceed += 1;
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });
              }
            })
            .finally(() => dialog.close());
        });
    },
    handlePrint() {
      const self = this;

      window.open(
        `${self.basePrintURL}/nota/${self.businessId}/${self.store.id}/${self.modal.form.transactionId}`,
        "_blank"
      );
    },
  },
};
</script>
